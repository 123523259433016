/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import * as Api from "service";
import useStore from "context";

import { useGetData } from "hooks";
import {
  SEARCH,
  IIKO_STATUSES_COLORS,
  DEFAULT_TOTAL,
  STORE_ERROR_MSG,
  INVENTORY_SEND_ERROR_MSG,
  IIKO_STATUSES,
} from "Constants";
import { Avatar } from "@material-ui/core";
import {
  changeVolumeValue,
  getLeftOver,
  getMeasurementName,
} from "helpers/getMeasurementName";
import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { useTranslation } from "localization/translation";
import { ChooseStore } from "components";

import "./list.scss";

const imageBase = process.env.REACT_APP_imagesBaseUrl;

const InventoryPrepare = () => {
  const [allInventories, setAllInventories] = useState([]);
  const [openIssuePopup, setOpenIssuePopup] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [unresolvedProductsIds, setUnresolvedProductsIds] = useState("");

  const {
    language,
    weightValue,
    volumeValue,
    setErrorMsg,
    setSuccessMsg,
    inventoryModal,
    setInventoryModal,
  } = useStore();

  const translation = useTranslation();
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get(SEARCH);

  const [inventories] = useGetData(Api.getInventoryPrepare.getById, id, search);

  const [stores] = useGetData(Api.getStores.getAll);

  const syncInventories = async (data) => {
    try {
      await Api.sendIikoInventory.create(data);
      setSuccessMsg(translation.inventory_send_message);
    } catch (error) {
      setErrorMsg(INVENTORY_SEND_ERROR_MSG);
    }
  };

  const handleSendProducts = () => {
    if (!storeId) {
      return setErrorMsg(STORE_ERROR_MSG);
    }
    const data = { storeId, inventoryId: id };

    syncInventories(data);
    setOpenChooseStore(false);
    setOpenIssuePopup(false);
    setStoreId("");
  };

  const handleOpenSelectStore = () => {
    setOpenChooseStore(true);
  };

  const handleSyncInventories = () => {
    if (inventories?.data?.unresolvedCount) {
      return setOpenIssuePopup(true);
    }

    handleOpenSelectStore();
  };

  const rows = allInventories?.map((item) => (
    <>
      <td>
        <Avatar
          className="mr-0"
          alt={item?.name_en}
          src={item?.image ? `${imageBase}${item?.image}` : imageBase}
        />
      </td>
      <td className="text-left py-0">
        <div className="font-size-sm">
          <b className={IIKO_STATUSES_COLORS[`${item?.integrationStatus}`]}>
            {item?.[`name_${language}`]} (
            {getMeasurementName(
              item?.measurement_unit,
              language,
              weightValue,
              volumeValue
            )}
            )
          </b>
        </div>
      </td>
      <td className="text-left py-0">
        <div className="font-size-sm">{item?.barcode}</div>
      </td>
      <td className="text-left py-0">
        <div className="font-size-sm">{item?.in_package ?? "-"}</div>
      </td>
      <td className="text-left py-0">
        <div className="font-size-sm">
          {item?.litrage ? changeVolumeValue(item?.litrage, volumeValue) : ""}
        </div>
      </td>
      <td
        className={`text-left py-0 ${
          item?.added_after_completed
            ? "blue_status"
            : item?.status?.toLowerCase()
        }`}
      >
        <div className="font-size-sm">
          {getLeftOver({
            unit: item?.measurement_unit,
            totalLiters: item?.total_liters,
            leftOver: item?.leftover,
            language,
            weight: weightValue,
            volume: volumeValue,
          })}
        </div>
      </td>
    </>
  ));

  useEffect(() => {
    if (inventories?.data?.items?.length) {
      let inventoryData = [];
      inventories?.data?.items?.forEach((elem) => {
        let data = [];
        elem?.goods?.forEach((item) => {
          data.push(item);
          if (
            item.integrationStatus === IIKO_STATUSES.resolveIssue ||
            item.integrationStatus === IIKO_STATUSES.unitResolveIssue
          ) {
            setUnresolvedProductsIds((prev) =>
              prev.concat(`${prev.length ? "," : ""}${item.id}`)
            );
          }
        });
        inventoryData = [...inventoryData, ...data];
      });
      setAllInventories(inventoryData);
    }
  }, [inventories]);

  return (
    <WrapperSeamless>
      <ChooseStore
        setOpen={setOpenChooseStore}
        open={openChooseStore}
        setStoreId={setStoreId}
        data={stores?.data}
        inventory_id={id}
        onClickHandler={handleSendProducts}
        isWarning={false}
        title={translation.choose_store}
      />
      <ChooseStore
        setOpen={setOpenIssuePopup}
        open={openIssuePopup}
        setStoreId={setStoreId}
        data={stores?.data}
        inventory_id={id}
        onClickHandler={handleSendProducts}
        unresolvedCount={inventories?.data?.unresolvedCount}
        isWarning={true}
        unresolvedProductsIds={unresolvedProductsIds}
        title={translation.choose_store}
      />
      <ChooseStore
        setOpen={setInventoryModal}
        open={inventoryModal}
        data={stores?.data}
        inventory_id={id}
        unresolvedCount={inventories?.data?.unresolvedCount}
        isWarning={false}
        isFirstRender={true}
        unresolvedProductsIds={unresolvedProductsIds}
        title={translation.unresolved_products}
      />
      <Table
        noDrag
        title={translation.inventory_prepare}
        items={allInventories}
        setItems={setAllInventories}
        total={DEFAULT_TOTAL}
        noCreateBtn
        actions={false}
        inventoryPrepare
        handleSyncInventories={handleSyncInventories}
        fields={
          <>
            <th className="text-left py-0">
              <span className="mr-3"> {translation.image}</span>
            </th>
            <th className="text-left py-0">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left py-0">
              <span className="mr-3"> {translation.barcode}</span>
            </th>
            <th className="text-left py-0">
              <span className="mr-3"> {translation.in_package}</span>
            </th>
            <th className="text-left py-0">
              <span className="mr-3"> {translation.bottle_volume}</span>
            </th>
            <th className="text-left py-0">
              <span className="mr-3"> {translation.left_over_ml}</span>
            </th>
          </>
        }
        rows={rows}
      />
    </WrapperSeamless>
  );
};

export default InventoryPrepare;
