import goodsLogo from "assets/images/wine.svg";
import goodTypesLogo from "assets/images/supermarket.svg";
import branchManagerLogo from "assets/images/branch-manager.svg";
import managerInvitationsLogo from "assets/images/manager-invitation.svg";
import managersLogo from "assets/images/manager.svg";
import settingsLogo from "assets/images/settings.svg";
import barmansListLogo from "assets/images/barman.svg";
import inventoriesLogo from "assets/images/inventory.svg";

export const CURRENT_TO_NEXT_LANG = {
  hy: "ru",
  ru: "en",
  en: "hy",
};

export const GOOD_TYPE = "goodType";
export const MANAGER_ROLE = "manager";

export const TEXT = "text";
export const EMAIL = "email";
export const PHONE = "phone";
export const NUMBER = "number";
export const PASSWORD = "password";
export const NEW_PASSWORD = "new-password";
export const STRING = "string";
export const REQUIRED = "required";
export const PASSWORD_INPUT = "Password";
export const IIKO_PASSWORD = "iiko_password";
export const LOGIN_INPUT = "Login";
export const IIKO_LOGIN = "iiko_login";
export const IIKO_HOST = "Iiko Host";
export const IIKO_HOST_KEY = "iiko_host";
export const LAST_ELEMENT_KEY = "iiko_password";
export const CHECK_IIKO_ERROR_MSG = "Incorrect Credentials";
export const FIXED_COUNT = 2;
export const PAGE = "page";
export const SIMPLE_COUNT = 1;

export const SUCCESS_EMAIL = "Email was sent";

export const VALIDATION_ERRORS = {
  phone: "Phone Number is not valid",
  password_en:
    "Your password must contain at least 8 characters without spaces.",
  password_hy: "Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ առանց բացատների:",
  password_ru: "Пароль должен содержать не менее 8 символов без пробелов.",
  email: "Email is not valid",
  number: "Number is not valid",
  required: "Required",
};

export const ROUTES = {
  dashboard: "/dashboard",
  sign_up: "/sign_up",
  forget_password: "/forget_password",
  sign_in: "/sign_in",
  terms: "/terms",
  privacy: "/privacy",
  edit_profile: "/dashboard/edit-profile",
  change_password: "/dashboard/password-change",
  iiko_unresolved: "/dashboard/iiko-unresolved",
};

export const TOKEN = "token";

export const ADD_ONE_TO_ORDER = 1;
export const DRAG_ALT = "Drag";
export const ORDER = "order";

export const SCROLL_DOWN = 1;
export const SCROLL_UP = -1;

export const OLD_PASSWORD = "old_password";
export const CONFIRMATION_PASSWORD = "password_confirmation";
export const OLD_PASSWORD_ERROR = "Wrong old password";
export const CONFIRMATION_PASSWORD_ERROR = "Invalid confirmation password";

export const ERROR_MESSAGE = "There was an error";

export const FUNCTION = "function";
export const SUCCESS_MESSAGE = "Updated";

export const NAME_EN = "name_en";
export const NAME_HY = "name_hy";
export const NAME_RU = "name_ru";

export const EMPTY_ARRAY_LENGTH = 0;

export const CHOOSE_US_SUCCESS_MSG = "Your message has been sent successfully";

export const INVENTORIES = "Inventory #";
export const EXPORT_AS = "export as";
export const PDF = "PDF";
export const EXCEL = "Excel";
export const TOTAL_LEFT_OVER = "Total left over";

export const HEADER_URLS = {
  sign_in: "/sign_in",
  sign_up: "/sign_up",
  forget_password: "/forget_password",
  reset_password: "/reset_password",
  verify_email: "/verify/email/:token",
  new_password: "/new-password/:token",
  register: "/register/continue/:token",
};

export const PHONE_ERROR_PARAM = "phone_number";
export const ACTIVE_STATUS_VALUE = 1;
export const INACTIVE_STATUS_VALUE = 0;
export const ACTIVE_STATUS = "activate";
export const INACTIVE_STATUS = "deactivate";
export const DELETE = "delete";
export const EDIT = "edit";
export const ACTION = "Action";
export const CREATE = "create";
export const SEARCH = "search";
export const LANGUAGE = "language";
export const PHONE_NUMBER_FIELD_NAME = "phone_number";
export const PHONE_FIELD_NAME = "phone";
export const GOOD_TYPE_DELETE_ERROR = "This good type contains goods";

export const NOT_SEARCHABLE_PAGES_PATHS = [
  "/dashboard/inventories",
  "/dashboard/goods-type",
];

export const BUTTON_VARIANTS = {
  contained: "contained",
};

export const NO_RESULT = "No result";

export const SECTIONS_NAME = {
  about_us: "about_us",
  how_it_work: "how_it_work",
  faq: "faq",
  contact_us: "contact_us",
  homeUrl: "home",
};

export const HEADER = {
  english: "Eng",
  russian: "Ru",
  armenian: "Hy",
};

export const INVENTORY_DATE_FORMAT = "DD/MM/YYYY HH:mm";
export const EMPTY_WEIGHT = 0;
export const PASSWORD_MIN_LENGTH = 5;

export const MEASUREMENT_OPTIONS_TEMPORARY = [
  {
    id: 1,
    name_en: "Milliliters",
    name_ru: "Миллилитр",
    name_hy: "Միլիլիտր",
    value_en: "ML",
    value_ru: "МЛ",
    value_hy: "ՄԼ",
    changed_name_en: "Liters",
    changed_name_ru: "Литр",
    changed_name_hy: "Լիտր",
    changed_value_en: "L",
    changed_value_ru: "Л",
    changed_value_hy: "Լ",
  },
  {
    id: 4,
    name_en: "Kilograms",
    name_ru: "Килограмм",
    name_hy: "Կիլոգրամ",
    value_en: "KG",
    value_ru: "КГ",
    value_hy: "ԿԳ",
    changed_name_en: "Grams",
    changed_name_ru: "Грамм",
    changed_name_hy: "Գրամ",
    changed_value_en: "Gr",
    changed_value_ru: "Гр",
    changed_value_hy: "Գր",
  },
  {
    id: 3,
    name_en: "Pieces",
    name_ru: "Штук",
    name_hy: "Հատ",
    value_en: "PC",
    value_ru: "ШТ",
    value_hy: "ՀՏ",
    changed_name_en: "Pieces",
    changed_name_ru: "Штук",
    changed_name_hy: "Հատ",
    changed_value_en: "PC",
    changed_value_ru: "ШТ",
    changed_value_hy: "ՀՏ",
  },
];

export const MEASUREMENT_KILOGRAM_OPTION = {
  id: 2,
  name_en: "Kilograms",
  name_ru: "Килограмм",
  name_hy: "Կիլոգրամ",
  value_en: "KG",
  value_ru: "КГ",
  value_hy: "ԿԳ",
  changed_name_en: "Grams",
  changed_name_ru: "Грамм",
  changed_name_hy: "Գրամ",
  changed_value_en: "Gr",
  changed_value_ru: "Гр",
  changed_value_hy: "Գր",
};

export const MILLILITERS_VALUE = 1;
export const GRAMS_VALUE = 4;
export const PEACE_VALUE = 3;
export const KILOGRAMS_VALUE = 2;
export const KILOGRAM_IN_GRAMS = 1000;
export const PASSWORD_ERROR_MESSAGE =
  "Please ensure that your password is at least 6 characters long.";
export const MIN_LENGTH_PASSWORD = 6;
export const WEIGHT = "weight";
export const VOLUME = "volume";
export const KILOGRAM = "kilogram";
export const GRAM = "gram";
export const LITER = "liter";
export const MILLILITER = "milliliter";
export const MAX_KILOGRAM_LITER_WEIGHT = 999;

export const IIKO_TABLE_TYPES = {
  iiko: "iiko",
  good: "good",
};

export const IIKO_FILTER_TABS = [
  {
    id: 1,
    name: "all",
    count_name: "total",
    status: "",
  },
  {
    id: 2,
    name: "unresolved",
    count_name: "unresolved",
    status: "2",
  },
  {
    id: 3,
    name: "unresolved_by_unit",
    count_name: "unitUnresolvedCount",
    status: "3",
  },
  {
    id: 4,
    name: "manually_resolved",
    count_name: "manuallyResolved",
    status: "4",
  },
  {
    id: 5,
    name: "solved",
    count_name: "resolved",
    status: "1",
  },
];

export const IIKO_FILTER_TABS_COUNT = {
  resolved: "resolved",
  unresolved: "unresolved",
  total: "total",
};

export const DEFAULT_ACTIVE_TAB = 1;
export const DEFAULT_TOTAL = 0;

export const GET_IIKO_COUNT = {
  resolved: ({ data }) => data.resolvedCount,
  manuallyResolved: ({ data }) => data.manuallyResolved,
  unitUnresolvedCount: ({ data }) => data.unitUnresolvedCount,
  total: ({ data }) => {
    const count =
      data.unitUnresolvedCount +
      data.unresolvedCount +
      data.resolvedCount +
      data.manuallyResolved;
    return count;
  },
  unresolved: ({ data }) => data.unresolvedCount,
};

export const IIKO_STATUSES = {
  match: 1,
  resolveIssue: 2,
  unitResolveIssue: 3,
  manuallyResolved: 4,
};

export const IIKO_STATUSES_COLORS = {
  "1": "success",
  "2": "issue",
  "3": "issue",
  "4": "success",
};

export const STATUS_LABEL_NAMES = {
  "1": "solved",
  "2": "unresolved",
  "3": "unresolved_by_unit",
  "4": "manually_resolved",
};

export const STATUS_LABEL_CLASS = {
  "1": "success",
  "2": "issue",
  "3": "issue",
  "4": "success",
};

export const FIRST_PAGE = 1;
export const LIMIT = 10;
export const DELAY = 500;
export const MIN_LENGTH_FOR_BORDER = 7;
export const MERGE_ERROR_MSG = "You must select from at least two tables";
export const STORE_ERROR_MSG = "Please select the store";
export const INVENTORY_SEND_ERROR_MSG = "Please try again";
export const DISABLE_AUTOCOMPLETE = "new-password";
export const INTEGRATION = "integration";
export const DEFAULT_UNRESOLVED_PRODUCTS_COUNT = 0;

export const SIDEBAR_MENU = {
  goods: goodsLogo,
  goods_type: goodTypesLogo,
  branch_management: branchManagerLogo,
  manager_invitations: managerInvitationsLogo,
  managers: managersLogo,
  barmen_list: barmansListLogo,
  inventories: inventoriesLogo,
  settings: settingsLogo,
  integration: inventoriesLogo,
};

export const SNACK_BAR = {
  duration: 4000,
  success: "success",
  error: "error",
  vertical_position: "top",
  horizontal_position: "right",
};

export const MANAGER_SIDEBAR = [
  {
    id: 1,
    href: "/dashboard/barmen",
    image: barmansListLogo,
    name: "barmen_list",
    alt: "Barmen List",
    subCategories: [],
  },
  {
    id: 2,
    href: "/dashboard/inventories",
    image: inventoriesLogo,
    name: "inventories",
    alt: "Inventories",
    subCategories: [],
  },
  {
    id: 3,
    href: "/dashboard/goods-type",
    image: goodTypesLogo,
    name: "goods_type",
    alt: "Goods type",
    subCategories: [],
  },
  {
    id: 4,
    href: "/dashboard/goods",
    image: goodsLogo,
    name: "goods",
    alt: "Goods",
    subCategories: [],
  },
  {
    id: 5,
    href: "/dashboard/iiko-integration",
    image: settingsLogo,
    name: "settings",
    alt: "Settings",
    subCategories: [
      {
        id: 1,
        link: "/dashboard/settings/integration",
        name: "integration",
      },
      {
        id: 2,
        link: "/dashboard/settings/measurement-unit",
        name: "measurement_unit",
      },
    ],
  },
  {
    id: 6,
    href: "/dashboard/iiko-integration",
    image: inventoriesLogo,
    name: "integration",
    alt: "Integration",
    subCategories: [
      {
        id: 1,
        link: "/dashboard/iiko-integration-products",
        name: "iiko_products",
      },
      // Temporary hide
      // {
      //   id: 2,
      //   link: "/dashboard/iiko-integration-semi-manufactures",
      //   name: "semi_manufactures",
      // },
    ],
  },
];

export const USER_ROLE = {
  manager: "manager",
  customer: "customer",
};

export const PATHNAMES = {
  good_types: "/dashboard/goods-type",
};

export const NUMBER_REGEX = /[^0-9]/g;
export const NUMBER_DOT_REGEX = /[^0-9.]/g;
export const ONE_DOT_REGEX = /(\..*)\./g;

export const REQUIRED_ERROR_MESSAGE = "This field is required";
