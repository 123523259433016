import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { TableElement } from "./table-element";
import { TableHeader } from "./table-header";
import Pagination from "@material-ui/lab/Pagination";
import useStore from "context";

import {
  IIKO_TABLE_TYPES,
  FIRST_PAGE,
  MIN_LENGTH_FOR_BORDER,
  ROUTES,
} from "Constants";

export const IikoUnsolvedTable = ({
  data,
  type,
  title,
  setSelectedElement,
  selectedElement,
  total,
  page,
  description,
  setQuery,
  limit,
  isEdit,
}) => {
  const count = Math.ceil(parseInt(total) / limit) || FIRST_PAGE;

  const { language } = useStore();
  const { state } = useLocation();
  const history = useHistory();

  const handleSelectElement = (id, measurement_unit) => {
    if (!isEdit) {
      setSelectedElement((prev) => ({
        ...prev,
        [type]: id === selectedElement[type] ? null : id,
        [`${type}_measurement_unit`]:
          id === selectedElement[type] ? null : measurement_unit,
      }));
      if (type === IIKO_TABLE_TYPES.good && state?.goods) {
        history.replace(ROUTES.iiko_unresolved, {
          iiko: state?.iiko,
          goods: state.goods,
        });
      }
    }
  };

  const handleChangePage = (e, page) => {
    setQuery((prev) => ({ ...prev, page }));
  };

  return (
    <div className="unsolved_table">
      <TableHeader title={title} description={description} />
      <div className="table_body">
        {data?.map((product) => (
          <TableElement
            data={product}
            id={product?.id}
            showBorder={data.length > MIN_LENGTH_FOR_BORDER}
            unit={
              type === IIKO_TABLE_TYPES.iiko
                ? product?.compare_unit
                : product?.measurement_unit
            }
            name={
              type === IIKO_TABLE_TYPES.iiko
                ? product?.name
                : product[`name_${language}`]
            }
            barcode={product?.barcode ?? "None"}
            key={product.id}
            handleSelectElement={handleSelectElement}
            activeClass={
              type === IIKO_TABLE_TYPES.iiko
                ? product.id === selectedElement.iiko
                  ? "active"
                  : ""
                : product.id === selectedElement.good
                ? "active"
                : ""
            }
          />
        ))}
      </div>
      <div className="pagination_section">
        <Pagination
          page={page}
          onChange={handleChangePage}
          className="pagination-primary"
          count={count}
        />
      </div>
    </div>
  );
};
